import apis from "../../common/js/api2";
import tools from '../../../extends/tools.js';
import JSONBIG from 'json-bigint';
import Printer from './printPrepare';
export default {
    async init() {
        //角色二订单打印
        this.selectPrinterFlag2 = true;
        this.ptype = 2;
        if (!this.printAction) {
            this.$alert('请指定打印来源！', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        //选择打印机
        this.selectPrinter();
        //
        apis.printList({
            type: this.ptype
        }).then((res) => {
            if (tools.msg(res)) {
                this.templateList_normal = res.data.lists;
            }
        }).catch((error) => {
            tools.err(error);
        });
        //获取对应的数据源字段，备货单和快递单，发货单不一样,
        apis.getDynamicField({
            type: this.ptype
        }).then((res) => {
            tools.msg(res) && (this.fieldList = res.data);
        }).catch((error) => {
            tools.err(error, this);
        });
        // 获取打印配置
        apis.getPrintSet().then(res => {
            if (tools.msg(res, this)) {
                this.printConfig = res.data.basic;
            }
        }).catch(err => {
            tools.err(err, this);
        });
        // 获取备货单聚合数据
        apis.getPrepareOrder({
            orders: JSON.stringify(this.orderIds),
            conditions: '[1,2]'
        }).then((res) => {
            if (tools.msg(res, this)) {
                this.prepareOrder = res.data.goods.list;
                if (!this.prepareOrder) {
                    this.$message({
                        type: "error",
                        message: "商品数据不完整，请重新获取！"
                    });
                }
            }
        }).catch((error) => {
            tools.err(error, this);
        });

    },
    printPrepare(mode) {
        // 初始化打印对象
        this.printer = new Printer.Printer(this, this);
        this.printer.printPrepare(mode);
    },
    // 快递模版
    manageExpressTpl() {
        this.$router.push({
            path: '/print'
        });
    },
    exit() {
        this.$emit('printLodopExit');
    },
    back() {
        this.$router.push({
            path: '/print'
        })
    },
    getTplById(id) {
        let tpl = null;
        this.templateList_normal.forEach((item, key) => {
            item.children.forEach((i, k) => {
                if (i.value === id) {
                    tpl = i;
                }
            });
        });
        return tpl;
    },
    selectPrinter() {
        //配置打印机
        let count = this.LODOP.GET_PRINTER_COUNT();
        if (count == 0) {
            this.$alert('无可用打印机', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        let arr = [];
        for (let i = 0; i < count; i++) {
            arr.push(this.LODOP.GET_PRINTER_NAME(i));
        }
        this.printerList = arr;
    },

}