import NZH from 'nzh';
import orderTrans from './orderTrans';
import apis from '../../common/js/api2';
import tools from '../../../extends/tools.js';

class Printer {
    constructor(host, vue) {
            this.host = host;
            this.trans = new orderTrans.OrderTrans(host);
            this.vue = vue;
        }
        // 打印发货单
    async printOrder(preview, isExpress) {
        if (!this.host.currentTemplate) {
            this.vue.$alert('请先选择一个模板！', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        //
        if (this.host.currentPrinter === '') {
            this.vue.$alert('请先选择一个打印机！', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        // 打印发货单为例，数据库存储的动态字段是 $$订单id$$  这样的信息，要想替换成动态数据
        // 需要先下载字段列表，字段列表里存储的是 [{name:'订单id',value:'id'}]这样格式的数据
        // 先用$$订单id$$去匹配订单id，获得对应的value值id,再根据类型，套入到对应的列表里获取实际的动态值
        let nzhcn = NZH.cn;
        // 先获取发货单模版信息
        let TPL = this.trans.getExpressTpl(this.host.currentTemplate);

        this.host.dragbox = JSON.parse(TPL.dragbox);
        // 获取订单信息
        let ORDER = this.host.orderList;
        let ORDER_IDS = [];
        //初始化
        ORDER.forEach((rowdata, index) => {
            // 记录订单数据
            ORDER_IDS.push(rowdata.id);
        });

        let orders = [];
        ORDER_IDS.forEach((item, key) => {
            orders.push({
                order_id: item
            });
        });
        await apis.saveOrderExpressStatus({
            orders: JSON.stringify(orders)
        }).then((res) => {
            if (!tools.msg(res, this.host)) {}
        }).catch((error) => {
            tools.err(error, this.host);
        });
        // 打印
        ORDER.forEach((rowdata, index) => {
            // 记录订单数据
            let printIndex = 'print' + index;
            this.host.LODOP.PRINT_INIT(printIndex);
            this.host.LODOP.SET_PRINT_PAGESIZE(1, this.host.paper.width * 10, this.host.paper.height * 10, printIndex);
            // 循环
            for (var i = 0; i < this.host.dragbox.length; i++) {
                let d = this.host.dragbox[i];
                let top = d.boxObject.top;
                let left = d.boxObject.left;
                let width = d.boxObject.width;
                let height = d.boxObject.height;
                let deg = d.boxObject.deg;
                let textFontSize = d.textObject.fontSizeNum;
                delete d.boxReverse.transform;
                delete d.boxReverse.writeingMode;
                //需要转换的动态数据
                for (let k in rowdata) {
                    //k是订单的字段信息，比如数量 num
                    //处理复杂字段
                    // console.log(d.source);
                    let flag2 = this.trans.getComplexDataFlag(d);
                    if ((d.type === 'text' && d.source === k) || flag2) {
                        // 填充样式
                        let allText = d.text + '';
                        // 替换动态数据
                        if (!!d.source && d.source === k) {
                            if (d.text) {
                                console.log(d.text);
                                allText = allText + ':' + rowdata[k];
                            } else {
                                allText = rowdata[k];
                            }
                        }
                        if (flag2) {
                            allText = this.trans.transOrderdata(d, rowdata);
                        }
                        // 转化
                        let textObject = d.textObject;
                        if (deg == 90) {
                            let tmp = width;
                            width = height;
                            height = tmp + 20;
                            top -= (height - width) / 2;
                            left += (height - width) / 2;
                        }
                        // 处理大写转换
                        if (textObject.textTransform == "uppercase") {
                            allText = nzhcn.toMoney(allText);
                            allText = allText.replace('人民币', '');
                        }
                        let className = 'text' + i;
                        this.host.LODOP.ADD_PRINT_TEXTA(className, top, left, width, height, allText); //增加超文本项
                        // 旋转变换
                        if (deg == 90) {
                            this.host.LODOP.SET_PRINT_STYLEA(className, "LineSpacing", -6);
                        }
                        // 处理居中居左
                        if (d.boxReverse.textAlign == 'center') {
                            this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 2);
                        } else if (d.boxReverse.textAlign == 'right') {
                            this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 3);
                        }
                        this.host.LODOP.SET_PRINT_STYLEA(className, 'FontName', textObject.fontFamily);
                        this.host.LODOP.SET_PRINT_STYLEA(className, 'FontSize', textFontSize);
                        if (textObject.fontWeigiht == 'bold') {
                            this.host.LODOP.SET_PRINT_STYLEA(className, 'Bold', 1);
                        }
                        if (textObject.fontStyle == 'italic') {
                            this.host.LODOP.SET_PRINT_STYLEA(className, 'Italic', 1);
                        }
                        if (textObject.textDecoration == 'underline') {
                            this.host.LODOP.SET_PRINT_STYLEA(className, 'Underline', 1);
                        }
                    }

                    // 转换条形码数据
                    if (d['type'] == 'barCode' && d.source === k) {
                        if (deg == 90) {
                            let tmp = width;
                            width = height;
                            height = tmp;
                            top -= (height - width) / 2;
                            left += (height - width) / 2;
                        }

                        this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, d.format, rowdata[k]);
                        if (deg == 90) {
                            this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
                        }
                    }
                    // 转换二维码
                    if (d['type'] == 'qr' && d.source == k) {
                        if (deg == 90) {
                            let tmp = width;
                            width = height;
                            height = tmp;
                            top -= (height - width) / 2;
                            left += (height - width) / 2;
                        }
                        // console.log(rowdata[k]);
                        this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, 'QRCode', rowdata[k]);
                        this.host.LODOP.SET_PRINT_STYLEA(0, "QRCodeVersion", 20);
                        if (deg == 90) {
                            this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
                        }
                    }
                }

                // 转换静态二维码
                if (d['type'] == 'qr') {
                    if (deg == 90) {
                        let tmp = width;
                        width = height;
                        height = tmp;
                        top -= (height - width) / 2;
                        left += (height - width) / 2;
                    }
                    if (!d.isDynamic) {
                        this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, 'QRCode', d.staticText);
                        this.host.LODOP.SET_PRINT_STYLEA(0, "QRCodeVersion", 20);
                    }
                    if (deg == 90) {
                        this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
                    }
                }

                //转换线条
                if (d['type'] == 'lineBox') {
                    let div = document.createElement('div');
                    for (let s in d.boxReverse) {
                        div.style[s] = d.boxReverse[s];
                    }
                    let l = document.createElement('div');
                    for (let s in d.lineStyle) {
                        l.style[s] = d.lineStyle[s];
                    }

                    div.appendChild(l);
                    this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, div.outerHTML);
                }

                // 转换图片
                if (d['type'] == 'image') {
                    let div = document.createElement('div');
                    for (let s in d.boxReverse) {
                        div.style[s] = d.boxReverse[s];
                    }
                    let img = document.createElement('img');
                    img.setAttribute('src', d.url);
                    img.style.width = '100%';
                    img.style.height = '100%';
                    div.appendChild(img);
                    this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, div.outerHTML); //增加超文本项
                }
                // 转换表格数据
                if (d['type'] == 'table') {
                    //创建表格
                    let tableDiv = document.createElement('div');
                    for (let s in d.boxReverse) {
                        tableDiv.style[s] = d.boxReverse[s];
                    }

                    let table = document.createElement('table');
                    // console.log(d.tableStyle)
                    for (let s in d.tableStyle) {
                        table.style[s] = d.tableStyle[s];
                    }
                    tableDiv.appendChild(table);
                    //创建行和列
                    for (let i = 0; i < d.rows.length; i++) {
                        let tr = document.createElement('tr');
                        for (let j = 0; j < d.rows[i].length; j++) {
                            let td = document.createElement('td');
                            td.setAttribute('cellspacing', 0);
                            let unit = d.rows[i][j];
                            for (let s in unit.style) {
                                td.style[s] = unit.style[s];
                            }
                            for (let k in rowdata) {
                                //动态数据
                                let sk = k;
                                //转换动态数据
                                if (unit.valueShow == sk && unit.isDynamic == '动态') {
                                    td.innerHTML = rowdata[k];
                                }
                                if (unit.isDynamic == '静态') {
                                    td.innerHTML = unit.valueShow;
                                }
                            }

                            tr.appendChild(td);
                        }

                        table.appendChild(tr);
                    }
                    this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, tableDiv.outerHTML); //增加超文本项
                }

                // 转换发货单数据
                if (d['type'] == 'shipment') {
                    //创建表格
                    let tableDiv = document.createElement('div');
                    for (let s in d.boxReverse) {
                        tableDiv.style[s] = d.boxReverse[s];
                    }

                    let table = document.createElement('table');
                    for (let s in d.tableStyle) {
                        table.style[s] = d.tableStyle[s];
                    }
                    tableDiv.appendChild(table);
                    //处理第一行表头
                    {
                        let i = 0;
                        let tr = document.createElement('tr');
                        for (let j = 0; j < d.rows[i].length; j++) {
                            let td = document.createElement('td');
                            td.setAttribute('cellspacing', 0);
                            let unit = d.rows[i][j];
                            for (let s in unit.style) {
                                td.style[s] = unit.style[s];
                            }
                            td.innerHTML = unit.valueShow;
                            tr.appendChild(td);
                        }
                        table.appendChild(tr);
                    }
                    // 统计总数问题
                    let totalObj = {
                        payment: 0, //实际支付总价
                        num: 0, //商品数量总数
                        price: 0 //商品价格总数
                    };
                    // 处理中间商品列表数据
                    {
                        // 获得商品数据
                        let GOODS = rowdata.list_info;
                        GOODS.forEach((item, key) => {
                            let tr = document.createElement('tr');

                            for (let j = 0; j < d.rows[0].length; j++) {
                                let fieldValue = this.trans.getOrderFieldValue(d.rows[0][j].valueShow);
                                let td = document.createElement('td');
                                td.setAttribute('cellspacing', 0);
                                // 因为本行是动态行，没有自己的样式，直接复制第一行的样式即可
                                let unit = d.rows[0][j];
                                for (let s in unit.style) {
                                    td.style[s] = unit.style[s];
                                }
                                //转换实际显示值
                                if (j === 0) {
                                    td.innerHTML = key + 1;
                                } else {
                                    let vv = this.trans.formatGoodsVal(fieldValue, item[fieldValue]);

                                    // 累积数量和价格
                                    totalObj[fieldValue] !== undefined && (totalObj[fieldValue] += parseFloat(vv));
                                    //
                                    td.innerHTML = this.trans.formatGoodsVal(fieldValue, item[fieldValue]);
                                }
                                tr.appendChild(td);
                            }
                            table.appendChild(tr);
                        });
                    }

                    // 处理尾部统计
                    {
                        let i = 0;
                        let tr = document.createElement('tr');
                        for (let j = 0; j < d.rows[i].length; j++) {
                            let td = document.createElement('td');
                            td.setAttribute('cellspacing', 0);
                            let unit = d.rows[i][j];
                            for (let s in unit.style) {
                                td.style[s] = unit.style[s];
                            }
                            let fieldValue = this.trans.getOrderFieldValue(d.rows[0][j].valueShow);
                            let txt = '';
                            if (unit.valueShow === '序号') {
                                txt = '汇总:';
                            } else {
                                txt = totalObj[fieldValue] !== undefined ? totalObj[fieldValue] : '';
                            }

                            td.innerHTML = txt;
                            tr.appendChild(td);
                        }
                        table.appendChild(tr);
                    }

                    this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, tableDiv.outerHTML); //增加超文本项
                }

                this.trans.getStaticHTML(d);
            }

            // 发送请求给lodop
            this.host.LODOP.SET_PRINTER_INDEX(this.host.currentPrinter);
            this.host.LODOP.SET_PRINT_COPIES(this.host.printCopies);
            if (preview === 1) {
                this.host.LODOP.PRINT();
            } else {
                this.host.LODOP.PREVIEW();
            }
            // 隐藏背景图片
        });
        if (preview <= 0) {
            return;
        }

        if (this.host.config.close) {
            setTimeout(() => {
                this.host.$emit('printLodopExit');
            }, 600);
        }
    }
}

export default {
    Printer
}