<template>
    <div>
        <div>
            <div style="max-width:95%;margin:0 auto;overflow-x:auto;">
                <el-row style="padding:10px 0;">
                    <p>
                        <span>订单编号：{{splitChildOrderList.id}}</span> 
                        <span style="display:inline-block;margin-left:50px;">订单金额：{{splitChildOrderList.payment}}</span>
                    </p>
                </el-row>
                <el-row style="padding:10px 0">
                    <p>
                        <span>收货地址：
                                {{splitChildOrderList.receiver_state}}
                                {{splitChildOrderList.receiver_city}}
                                {{splitChildOrderList.receiver_district}}
                                {{splitChildOrderList.receiver_town}}
                                {{splitChildOrderList.receiver_address}}    
                        </span> 
                    </p>
                </el-row>
                <table class="mytable">
                    <thead class="thead">
                        <tr class="rows">
                            <td class="cols"><el-checkbox @change="checkSplitOrder(true)" :indeterminate="splitCheckStatus" v-model="checkAllSplitOrder"></el-checkbox></td>
                            <td class="cols">序号</td>
                            <td class="cols">数量</td>
                            <td class="cols">剩余数量</td>
                            <td class="cols">拆分订单数量</td>
                            <td class="cols">宝贝名称</td>
                            <td class="cols">实付金额</td>
                        </tr>
                    </thead>
                    <tbody class="tbody" v-if="splitChildOrderList.list_info.length>0"> 
                        <tr v-for="(item,key) in splitChildOrderList.list_info" :key="key" class="rows" v-show="item.num-item.all_split_num>0" :class="[key%2==0?'odd':'']">
                            <td class="cols order"><el-checkbox v-model="item.splitCheck" @change="checkSplitOrder(false,item,'single')"></el-checkbox></td>
                            <td class="cols order">{{key+1}}</td>
                            <td class="cols order">{{item.num}}</td>
                            <td class="cols order">{{item.num-item.all_split_num-item.split_num}}</td>
                            <td class="cols order"><el-input-number :min="0" :max="item.num-item.all_split_num" @change="checkSplitOrder(false,item,'number')" v-model="item.split_num"></el-input-number></td>
                            <td class="cols order">{{item.product_information.sku_properties_name}}</td>
                            <td class="cols order">{{item.payment}}</td>
                        </tr>
                    </tbody>

                    <tbody class="tbody" v-else>
                        <tr class="rows " >
                            <td class="cols" colspan="7" >
                                <i class="el-icon-loading" style="font-size:22px;"></i>
                            </td>
                        </tr>
                    </tbody>   
                </table>
            </div>
        </div>
        <div style="text-align:left;height:50px;line-height:50px;">
            <el-button type="primary" icon="el-icon-menu" size="mini" style="margin-left:2.5%;"  @click="createSubOrder">生成子订单</el-button>
        </div>
        <el-row style="height:380px;overflow:auto;border:1px solid #ccc;width:95%;margin:0 auto;">
            <div class="order">
                <!-- 动态生成的子订单 -->
                <table class="mytable">
                    <thead class="thead">
                        <tr class="rows">
                            <td class="cols">序号</td>
                            <td class="cols">数量</td>
                            <td class="cols">宝贝名称</td>
                            <td class="cols">实付金额</td>
                        </tr>
                    </thead>
                    <tbody class="tbody" v-for="(item,key) in activeSplitOrder"  :key="key"> 
                        <tr><td colspan="4" style="height:40px;line-height:40px;text-align:center;background:#ffffdd;font-weight:900;">
                                <span style="color:blue;">子订单 {{key+1}}</span> <el-button size="mini" type="danger" @click="restoreSplitOrder(item,key)">删除</el-button>
                            </td></tr>
                        <tr  class="rows" v-for="(i,k) in item" :key="k" :class="[k%2==0?'odd':'']">
                            <td class="cols order">{{k+1}}</td>
                            <td class="cols order">{{i.split_num}}</td>
                            <td class="cols order">{{i.product_information.sku_properties_name}}</td>
                            <td class="cols order">{{i.payment}}</td>
                        </tr>
                    </tbody>
                </table>                    
            </div>
        </el-row>
        <div style="text-align:right;margin-top:10px;">
            <el-button type="success" icon="el-icon-upload2"
                size="small" style="margin-right:2.5%;" title="提交数据到云端才能真正拆分订单哦！" 
                @click="splitOrderSubmit">提交数据到云端</el-button>
        </div>
    </div>
</template>

<script>
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
import JSONBIG from 'json-bigint'

export default {
    props:{
        //订单id
        orderId:{
            type:String,
            default:'0'
        }
    },
    destroyed(){
        this.splitChildOrderList = [
            {
                id:1,
                list_info:[]
            }            
        ];
        this.activeSplitOrder = [];
        this.submitSplitOrder = [];
    },
    data(){
        return {
            //拆分的
            splitChildOrderList: [
                {
                    id:1,
                    list_info:[]
                }
            ],
            //分割订单全选按钮,1代表无选中，2代表有选中
            splitCheckStatus: false,
            checkAllSplitOrder: false,
            //多次分割的动态数据
            activeSplitOrder: [
                // //第一次
                // [{
                //     order: {},
                //     split: 3
                // }, {
                //     id: {},
                //     split: 4
                // }],
                // //第二次
                // [{
                //     id: {},
                //     split: 3
                // }, {
                //     id: {},
                //     split: 4
                // }]
            ],
            // 提交分割订单的干净数据
            submitSplitOrder: [
                //第一次
                // [{
                //     id: 123,
                //     split: 3
                // }, {
                //     id: 333,
                //     split: 4
                // }],
                // //第二次
                // [{
                //     id: 123,
                //     split: 3
                // }, {
                //     id: 333,
                //     split: 4
                // }]
            ]

        }
    },
    mounted(){
        this.init();
    }, 
    methods:{
        //初始化分割订单数据
        init() {
            //获取订单数据
            this.getOrderDetail();
            this.activeSplitOrder = [];
        },
        exit(){
            this.$emit('exit');
        },
        //获取订单详情
        getOrderDetail() {
            apis.orderDetail({ id:this.orderId}).then((res) => {
                if (res.code != 200) {
                    this.$message(res.msg);
                    return;
                }
                let element = res.data;
                //转换数值显示方式
                for (let field in element) {
                    if (field === 'adjust_fee' || field === 'discount_fee' || field === 'payment') {
                        element[field] = tools.setPrice(element[field]);
                    }
                }
                element.list_info.forEach((item, key) => {

                    //把商品数据对象化
                    item.product_information = JSON.parse(item.product_information);

                    for (let field in item) {
                        if (field === 'divide_order_fee' ||
                            field === 'adjust_fee' ||
                            field === 'discount_fee' ||
                            field === 'total_fee' ||
                            field === 'price' ||
                            field === 'payment') {
                            item[field+'_real'] = item[field];
                            item[field] = tools.setPrice(item[field]);
                        }

                        this.$set(item, 'split_num', 0);
                        this.$set(item, 'all_split_num', 0); //已分割的数量累加
                        this.$set(item, 'least_num', item.num + 0);
                        this.$set(item, 'splitCheck', false);
                    }
                });

                this.splitChildOrderList = element;
                this.$set(this.splitChildOrderList,'list_info',element.list_info);

            }).catch((error) => {
                // console.log(error);
                this.$message('网络或程序错误，请稍候重试！');
            });
        },
        // 单选
        checkSplitOrder(isAll, subOrder, modal) {
            if (!!modal) {
                //修改分割数量时，选中订单
                if (modal === 'number') {
                    //总分割次数
                    if (subOrder.split_num > 0) {
                        subOrder.splitCheck = true;
                    } else {
                        subOrder.splitCheck = false;
                    }
                }
                //选中订单时，把分割数量设置为当前商品总数
                if (modal === 'single') {
                    if (subOrder.splitCheck) {
                        subOrder.split_num = subOrder.num;
                    } else {
                        subOrder.split_num = 0;
                    }
                }
            }
            //全选
            if (isAll) {
                this.splitChildOrderList.list_info.forEach(item => {
                    item.splitCheck = this.checkAllSplitOrder;
                    item.split_num = item.num;
                });
            }

            //判断是全选还是部分选
            let counter = this.splitChildOrderList.list_info.filter((item, index) => {
                return item.splitCheck;
            }).length;
            if (counter === 0) {
                this.checkAllSplitOrder = false;
                this.splitCheckStatus = false;
            } else if (counter === this.splitChildOrderList.list_info.length) {
                this.splitCheckStatus = false;
                this.checkAllSplitOrder = true;
            } else {
                this.splitCheckStatus = true;
                this.checkAllSplitOrder = false;
            }
        },

        //生成子订单
        /**
         * 生成子订单，就是遍历👆的订单数据，如果split_num > 0 ，就生成一个子订单到
         * 每点击一次按钮，就往
         */
        createSubOrder() {
            //常规拆单
            let arr = [];
            let flag1 = true; //检验标志1，单个订单
            let flag2 = true; //检验标志2，多个订单
            this.splitChildOrderList.list_info.forEach((item, key) => {
                let numAll = item.split_num + item.all_split_num;
                
                // 如果拆单数量和父订单商品数量一样时，订单必须大于1个
                // console.log(item.num);
                // console.log(numAll);
                if (item.num === numAll && this.splitChildOrderList.list_info.length === 1) {
                    flag1 = false;
                    return;
                }
                //父订单数量大于1时，逐个检查是否满足拆单条件
                let counter1 = 0;
                if (this.splitChildOrderList.list_info.length > 1) {
                    this.splitChildOrderList.list_info.forEach((i, k) => {
                        //计算商品总数
                        let numAll = i.split_num + i.all_split_num;
                        if (i.num === numAll) {
                            counter1++;
                        }
                    });
                    if (counter1 === this.splitChildOrderList.list_info.length) {
                        flag2 = false;
                        return;
                    }
                }

                //插入分割数量大于0的订单
                let item_tmp = tools.copy(item);
                // 拆分后的价格
                item_tmp.payment = tools.setPrice((item_tmp.payment_real*item_tmp.split_num/item_tmp.num),2);
                item.split_num > 0 && arr.push(item_tmp);
                item.all_split_num += item.split_num;
                item.split_num = 0;
                item.splitCheck = false;
            });

            if (!flag1 || !flag2) {
                // this.$message('!');
                this.$alert('拆分订单只能限制部分明细或者部分数量')
                return;
            }

            if (arr.length <= 0) {
                this.$alert('至少要选择一个商品进行拆分')
                return;
            }

            this.activeSplitOrder.push(arr);
        },

        //还原子订单到父订单中
        restoreSplitOrder(item, key) {
            item.forEach((i, k) => {
                //已有基础上修改
                let hasId = false;
                this.splitChildOrderList.list_info.forEach((item, key) => {
                    if (i.id == item.id) {
                        item.all_split_num -= i.split_num;
                        item.splitCheck = false;
                        hasId = true;
                    }
                });
                //全部拆分还原
                if(!hasId){
                    this.splitChildOrderList.list_info.push(i);
                }
            });

            this.activeSplitOrder.splice(key, 1);
        },

        // 提交拆分订单到服务器
        splitOrderSubmit() {
            if (this.activeSplitOrder.length < 1) {
                this.$alert('请最少拆分一个订单');
                return;
            }
            //组装数组
            // console.log(this.activeSplitOrder);
            this.activeSplitOrder.forEach((item, key) => {
                let arr = [];
                //遍历组装数据
                item.forEach((item, key) => {
                    let orderTimer = {
                        id: item.id,
                        split: item.split_num
                    };

                    arr.push(orderTimer);
                });

                this.submitSplitOrder.push(arr);
            });
            //
            const loading = this.$loading({
                lock: true,
                text: '提交数据中，请稍候...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            apis.splitOrder({
                orders: JSONBIG.stringify(this.submitSplitOrder)
            }).then((res) => {
                loading.close();
                if (res.code != 200) {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                    return;
                }
                //刷新订单
                this.init();
                this.activeSplitOrder = [];
                this.submitSplitOrder = [];
                //初始化分割数据
                this.$message({
                    type: 'success',
                    message: '拆分订单成功！'
                });
            }).catch((error) => {
                this.$message('网络或程序错误，请稍候重试！');
                loading.close();
            });
        },
    }
}
</script>

<style scoped lang="less">
    @import url('../../../assets/less/normal');
    @import url('../less/order.less');

</style>