<template >
   <div id="root" >
        <!-- 配置打印机-角色二快递单打印 -->
        <div
        style="width:1190px;height:614px;margin:0 auto;"
        v-if="selectPrinterFlag3"
        center>
            <div class="top">
                <el-radio-group v-model="expressMode">
                    <el-radio-button label="普通"></el-radio-button>
                    <el-radio-button label="菜鸟"></el-radio-button>
                </el-radio-group>
                <!-- 模版类型 -->
                <span style="dispaly:inline-block;margin-left:88px;">
                选择模版：
                <!-- 普通模版 -->
                <el-cascader
                    placeholder="请选择快递模版"
                    style="width:160px;"
                    v-model="currentTemplate" v-if="expressMode === '普通'"
                    :props="{
                        expandTrigger: 'hover',
                        emitPath:false
                    }"
                    :options="templateList_normal"
                    size="small"
                    >
                </el-cascader>
                <router-link 
                v-if="expressMode === '普通'" 
                :to="{path:'/print'}"
                target="_blank"
                style="margin-left:6px;">管理模版</router-link>
                <!-- 菜鸟模版 -->
                <el-select v-model="templateId" 
                    v-if="expressMode === '菜鸟'" 
                    placeholder="请选择电子面单" size="small"  style="width:160px;">
                    <el-option
                    @click.native="changeTemplate(item)"
                    v-for="item in templateList_cainiao"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
                <router-link 
                v-if="expressMode === '菜鸟'" 
                :to="{path:'/cainiao/lists'}"
                target="_blank"
                style="margin-left:6px;">管理模版</router-link>
                <!-- lodop打印机 -->
                <span 
                    v-if="expressMode === '普通'"
                    style="dispaly:inline-block;margin-left:156px;">
                    选择打印机：
                    <el-select v-model="currentPrinter" placeholder="请选择打印机" size="small" style="width:160px;">
                        <el-option
                        v-for="(item,key) in printerList"
                        :label="item"
                        :value="key"
                        :key="key"></el-option>
                    </el-select>
                </span> 
                <!-- 菜鸟打印机 -->
                <span 
                    v-if="expressMode === '菜鸟'"
                    style="dispaly:inline-block;margin-left:156px;">
                    选择打印机：
                    <el-select v-model="currentPrinterCainiao" placeholder="请选择打印机" size="small" style="width:160px;">
                        <el-option
                        v-for="(item,key) in printerListCainiao"
                        :label="item.name"
                        :value="item.name"
                        :key="key"></el-option>
                    </el-select>
                </span> 

                </span>
            </div>
            <!-- 菜鸟账户 -->
            <div style="height:36px;background:#fff;line-height:36px;" >
                <!-- <el-button type="primary" @click="showCainiao">菜鸟账户</el-button> -->
                <span style="display:inline-block;margin-left:5px;" v-if="expressMode === '菜鸟'">
                    当前菜鸟账户: 
                    <span v-if="templateId===''" style="display:inline-block;margin-left:5px;color:red;">
                        选择模版后显示
                    </span>
                    <span v-if="templateId>0">
                        <span style="display:inline-block;margin-left:5px;color:blue;">
                            {{cainiaoTemplateDetail.cainiao_waybill_address_info.shop_info?cainiaoTemplateDetail.cainiao_waybill_address_info.shop_info.name:''}} 
                            {{cainiaoTemplateDetail.cainiao_waybill_address_info.shop_info?cainiaoTemplateDetail.cainiao_waybill_address_info.branch_name:''}} 
                            {{cainiaoTemplateDetail.cainiao_waybill_address_info.shop_info?cainiaoTemplateDetail.cainiao_waybill_address_info.branch_code:''}} 
                        </span>
                        <span  style="display:inline-block;margin-left:25px;">
                            剩余 : <span style="color:#f00;">{{cainiaoTemplateDetail.cainiao_waybill_address_info.quantity}} </span> 单号
                        </span>
                    </span>
                    <!-- <a href="javascript:void(0)" style="display:inline-block;margin-left:15px;">
                        充值单号
                    </a> -->
                </span>
            </div>

            <div class="order_list" style="overflow:auto;">
                <table class="mytable" style="width:100%;">
                        <thead class="thead">
                            <tr class="rows ">
                                <td class="cols">序号</td>
                                <td class="cols">运单号</td>
                                <td class="cols">商品明细</td>
                                <td class="cols">商品数量</td>
                                <td class="cols">收货省市</td>
                                <td class="cols" v-if="expressMode === '菜鸟'">打印单数量</td>
                                <td class="cols">打印状态</td>
                            </tr>
                        </thead>
                        <tbody class="tbody"  v-for="(i,k) in orderList" :key="i.id">
                            <tr class="rows " :class="k%2==0?'odd':''">
                                <td class="cols">{{k+1}}</td>
                                <td class="cols title">
                                    <span v-if="expressMode === '普通'">
                                        <el-input size="small" 
                                            v-model="i.waybill_code" 
                                            @change="saveWaybillCode(i)" 
                                            style="height:30px;"></el-input>
                                    </span>
                                    <div v-if="expressMode === '菜鸟'">
                                        <div v-if="i.waybill_info.length>1" @click="i.showCainiaoDetail = !i.showCainiaoDetail" href="javascript:void(0);" style="width:260px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:blue;cursor:pointer;">
                                            <span v-for="(i3,k3) in i.waybill_info" :key="k3">
                                                {{i3.waybill_code}}
                                            </span>
                                        </div>
                                        <span v-else> 
                                            <a v-if="i.waybill_info.length>0" @click="i.showCainiaoDetail = !i.showCainiaoDetail" href="javascript:void(0);">
                                                {{i.waybill_code}}
                                            </a>
                                        </span>
                                    </div>
                                </td>
                                <td class="cols">
                                    <div style="width:340px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                                        <a href="javascript:void(0)" @click="i.showGoodsDetail = !i.showGoodsDetail" :title="i.titleStr">{{i.titleStr}}</a>
                                    </div>
                                    
                                </td>
                                <td class="cols">{{i.list_info.all_num}}</td>
                                <td class="cols">
                                    {{i.receiver_state}}
                                    {{i.receiver_city}}
                                </td>
                                <td class="cols" v-if="expressMode === '菜鸟'"><el-input-number v-model="i.print_number" size="mini" :max="100" :min="1"></el-input-number></td>
                                <td class="cols">
                                    <span v-if="!i.is_print_express">未打印</span>
                                    <span v-if="i.is_print_express===1">已打印</span>
                                </td>
                            </tr>
                            <tr class="rows" v-show="i.showGoodsDetail">
                                <td colspan="5" class="cols" style="position:relatvie;">
                                    <div style="min-height:100px;background:#fff;">
                                        <table class="mytable" style="width:100%;">
                                                <thead class="thead">
                                                    <tr class="rows ">
                                                        <td class="cols" style="padding:3px;">序号</td>
                                                        <td class="cols" style="padding:3px;">商品名称</td>
                                                        <td class="cols" style="padding:3px;">数量</td>
                                                    </tr>
                                                </thead>
                                                <tbody class="tbody">
                                                     <tr v-for="(ii,kk) in i.list_info" :key="kk" :class="kk%2==0?'odd':''">
                                                         <td class="cols" style="padding:1px;">{{kk+1}}</td>
                                                         <td class="cols" style="padding:1px;">{{ii.title}}</td>
                                                         <td class="cols" style="padding:1px;">{{ii.num}}</td>
                                                     </tr>
                                                     <tr>
                                                         <td class="cols" colspan="1" style="text-align:right;width:50px;">
                                                            合计：
                                                         </td>
                                                         <td class="cols" colspan="2" style="text-align:right;">
                                                             <span style="display:inline-block;position:relative;right:20px;">{{i.list_info.all_num}}</span>
                                                         </td>
                                                     </tr>
                                                </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>

                            <tr class="rows" v-show="i.showCainiaoDetail">
                                <td colspan="5" class="cols" style="position:relatvie;">
                                    <div style="min-height:100px;background:#fff;">
                                        <table class="mytable" style="width:100%;">
                                                <thead class="thead">
                                                    <tr class="rows ">
                                                        <td class="cols" style="padding:3px;">序号</td>
                                                        <td class="cols" style="padding:3px;">运单号</td>
                                                        <td class="cols" style="padding:3px;">快递公司</td>
                                                        <td class="cols" style="padding:3px;">操作</td>
                                                    </tr>
                                                </thead>
                                                <tbody class="tbody">
                                                     <tr v-for="(ii,kk) in i.waybill_info" :key="kk" :class="kk%2==0?'odd':''">
                                                         <td class="cols" style="padding:1px;">{{kk+1}}</td>
                                                         <td class="cols" style="padding:1px;">{{ii.waybill_code}}</td>
                                                         <td class="cols" style="padding:1px;">{{ii.logistics_companies_info.name}}</td>
                                                         <!-- <td class="cols" style="padding:1px;">已打印</td> -->
                                                         <td class="cols" style="padding:1px;">
                                                             <a href="javascript:void(0);" @click="printCainiaoOld(ii,i)">再次打印</a>
                                                         </td>
                                                     </tr>
                                                </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody class="tbody" v-if="orderList.length === 0">
                            <tr class="rows " >
                                <td class="cols" colspan="7" >
                                    <i class="el-icon-loading" style="font-size:22px;"></i>
                                </td>
                            </tr>
                        </tbody>                                                
                    </table>
            </div>

            <div class="footer">
                <span style="color: #3b85ff;font-size:16px;font-weight:700;">
                    已打: <span style="color:rgb(236, 61, 100);">{{sendOrderLength}}</span>/{{orderLength}}个订单
                </span>
                <span style="margin-left:5px;">
                    <a href="javascript:void(0);">打印设置</a>
                </span>

                <div style="position:absolute;right:0px;height:60px;background:#fff;width:80%;top:0px;text-align:right;">
                    <el-button size="mediun" v-if="expressMode === '普通'" @click="printOrder(-1)">预览</el-button>
                    <!-- <el-button size="mediun" v-if="expressMode === '菜鸟'" @click="printCainiao(-1)">预览2</el-button> -->
                    <span v-if="expressMode === '菜鸟'">
                        <!-- <el-button size="mediun" @click="getNumber" style="margin-left:20px;">获取单号</el-button> -->
                        <el-button size="mediun" @click="printCainiao(1)" type="primary" style="margin-left:20px;">获取单号并打印菜鸟面单</el-button>
                        <!-- <el-button size="mediun" @click="printCainiao" type="danger" style="margin-left:20px;">打印旧的面单</el-button> -->
                    </span>

                    <span v-if="expressMode === '普通'">
                        <el-button 
                        @click="printOrder(1)"
                        size="mediun" 
                        type="primary" style="margin-left:20px;">打印快递单</el-button>
                    </span>
                    
                    <!-- <el-button size="mediun" style="margin-left:20px;">去打印发货单</el-button> -->
                    <el-button-group style="margin-left:20px;">
                        <el-button size="mediun" style="height:40px;">发货(自己联系)</el-button>
                        <el-button size="mediun" style="height:40px;" icon="el-icon-more"></el-button>
                    </el-button-group>
                </div>
            </div>
        </div> 

        <!-- 安装LODOP -->
        <div
        v-if="installLodop"
        width="70%"
        >
            <div style="text-align:left;">
                <p style="width:80%;font-size:20px;line-height:40px;margin:0 auto;color:red;">检测到您的电脑未安装LODOP打印控件，请安装后再继续进行打印</p>
                <p style="width:80%;font-size:23px;line-height:40px;color:blue;margin:0 auto;">安装步骤：</p>
                <p style="width:80%;font-size:20px;line-height:40px;margin:0 auto;">1.打开LODOP <a target="_blank" href="http://www.c-lodop.com/download.html">官网-www.c-lodop.com</a>,根据您的操作系统选择合适的空间版本进行安装</p>
                <p style="width:80%;font-size:20px;line-height:40px;margin:0 auto;">2.或直接点击这里下载 →→ <a target="_blank" href="http://www.c-lodop.com/download/Lodop6.226_Clodop4.127.zip">LODOP打印控件</a> ，解压后安装</p>
                <p style="width:80%;font-size:20px;line-height:40px;margin:0 auto;">3.安装完成后启动LODOP空间，启动成功界面如图所示，右下角会有图标常驻：</p>
                <p style="width:80%;font-size:20px;line-height:40px;margin:0 auto;"><img :src="require('../../assets/img/home/lodop1.png')" />-<img :src="require('../../assets/img/home/lodop2.png')" /></p>
                <p style="width:80%;font-size:20px;margin:0 auto;height:80px;line-height:80px;">3.以上步骤执行成功后，点击&nbsp;这里 →→&nbsp;&nbsp; <a href="javascript:location.reload();" style="font-size:30px;">重新打印</a> ←← 即可继续打印 </p>
            </div>            
        </div>  
  </div>
</template>
<script>
import methods from './printExpress/methods.js';
import datas from './printExpress/object.js';
import tools from '../../extends/tools';

export default {
    name:'printExpress',
    inject: ['reload'],  //添加此行
    props:{
        c_status:{
            type:Boolean,
            default:false
        },
        printId:{
            type:Number,
            default:0
        },
        //角色二批量打印时的订单信息
        orderIds:{
            type:Array,
            default:()=>{
                return [];
            }
        },
        // 模版类型,对应模版类型里的4个id，1发货单，2备货单，3普通快递单
        tplType:{
            type:Number,
            default:1
        },
        //打印动作,simple为角色一打印，order为角色2订单打印
        printAction:{
            type:String,
            default:'simple'
        },
        //订单打印动作,prepare备货单，shipment发货单，express快递单
        orderAction:{
            type:String,
            default:'prepare'
        }
    },
    data(){
        return datas;
    },
    methods:methods,
    destroyed(){
        // 退出时初始化数据
        this.selectPrinterFlag1 = false;
        this.selectPrinterFlag2 = false;
        this.selectPrinterFlag3 = false;
        this.currentPrinter = '';
        this.currentTemplate = '';
        this.orderList = '';
    },
    created(){
        
    },
    mounted() {
        //角色一连续打印数据
        if(!this.LODOP){
            this.installLodop = true;
            return;
        }

        this.init();
    }
}

</script>

<style scoped lang="less">
@import url('../../assets/less/normal');
.top{
    height:47px;background:#fff;
}
.order_list{
    height:490px;background:#fff;
}
.footer{
    height:60px;background:#fff;position:relative;line-height:60px;
}
.cols{
    height:30px;
}
.cainiao_row{
    height:57px;line-height:60px;
}
.odd{
    .cols{
        background: #fafafa;
    }
}

</style>