<template>
  <el-row style="min-height:580px;overflow:auto;margin:0 auto;">
        <div class="order">
            <div style="height:45px;line-height:45px;font-size:16px;text-align:center;color:blue;">**在合并订单时会匹配所有选中的字段进行合并**</div>
            <!-- 动态生成的子订单 -->
            <table class="mytable">
                <thead class="thead">
                    <tr class="rows">
                        <td class="cols">字段名称</td>
                        <td style="text-align:center;"><el-checkbox 
                            :indeterminate="mergeOrderstrategyCheckMutiple" 
                            @change="mergeStrategyChange(null,'all')"
                            v-model="mergeOrderstrategyCheckAll"></el-checkbox>
                        </td>
                    </tr>
                </thead>
                <tbody class="tbody" > 
                    <tr  class="rows" v-for="(item,key) in mergeOrderstrategyList" :key="key" :class="[key%2==0?'odd':'']">
                        <td class="cols order">{{item.name}}</td>
                        <td class="cols order" >
                            <el-checkbox v-model="item.isCheck" @change="mergeStrategyChange(item,'single')"></el-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div  colspan="2" style="height:50px;line-height:50px;text-align:center;">
                <!-- <el-button type="danger" size="small" @click="mergeOrderstrategyFlag=false;" style="margin-right:30px;">关闭</el-button> -->
                <el-button type="primary" size="small" @click="mergeStrategySave">保存</el-button>
            </div>                    
        </div>
    </el-row>
</template>

<script>
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
import JSONBIG from 'json-bigint'

export default {
    data(){
        return {
            //合并checkbox
            mergeOrderstrategyCheckMutiple: true,
            mergeOrderstrategyCheckAll: false,
            // 合并策略数组
            mergeOrderstrategyList: [],
        }
    },
    methods:{
        showMergestrategy() {
                apis.strategyList().then((res) => {
                    this.mergeOrderstrategyList = res.data;
                    this.mergeOrderstrategyList.forEach(item => {
                        this.$set(item, 'isCheck', item.checked === 1);
                    })
                }).catch((err) => {
                    console.log(err);
                    this.$alert('网络或程序错误，请稍候重试！');
                });
        },
        mergeStrategyChange(item, modal) {
            if (modal == 'all') {
                this.mergeOrderstrategyList.forEach(item => {
                    item.isCheck = this.mergeOrderstrategyCheckAll;
                })
            }
            //计算是否全选
            let counter = 0;
            this.mergeOrderstrategyList.forEach(item => {
                if (item.isCheck) {
                    counter++;
                    item.checked = 1;
                } else {
                    item.checked = 2;
                }
            });

            if (counter === this.mergeOrderstrategyList.length) {
                this.mergeOrderstrategyCheckMutiple = false;
                this.mergeOrderstrategyCheckAll = true;
            } else {
                if (counter > 0) {
                    this.mergeOrderstrategyCheckMutiple = true;
                } else {
                    this.mergeOrderstrategyCheckMutiple = false;
                }
            }
        },
        mergeStrategySave() {
            apis.strategySave({ value: JSONBIG.stringify(this.mergeOrderstrategyList) })
                .then((res) => {
                    tools.msg(res, this,1);
                    this.$emit('exit');
                })
                .catch((error) => {
                    tools.err(error, this);
                });
        },
    },
    mounted(){
        this.showMergestrategy();
    }   
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
@import url('../less/order.less');
</style>