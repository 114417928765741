import NZH from 'nzh';
import orderTrans from './orderTrans';
import apis from '../../common/js/api2';
import tools from '../../../extends/tools.js';

class Printer {
    constructor(host, vue) {
        this.host = host;
        this.trans = new orderTrans.OrderTrans(host);
        this.vue = vue;
        this.pageSize = 5;
        this.emptyLine = true;
        let TPL = this.trans.getShipmentTemplate(this.host.currentTemplate);
        this.host.paper = JSON.parse(TPL.paper);
        this.host.dragbox = JSON.parse(TPL.dragbox);
        //给分页和空行赋值
        this.host.dragbox.forEach(item => {
            if (item.type === 'prepare') {
                this.pageSize = item.pageSize ? item.pageSize : 5;
                this.emptyLine = item.emptyLine;
                this.borderLine = item.borderLine;
                let tdStyle = '';
                if (this.borderLine) {
                    tdStyle = '1px solid #000;';
                } else {
                    tdStyle = 'none';
                }
                this.tdStyle = tdStyle;
            }
        });
    }

    printPrepare(model) {
        if (!this.host.prepareOrder) {
            this.host.$message({
                type: "error",
                message: "商品数据不完整，请重新获取！"
            });
            return;
        }
        //打印次数由分页和总条数决定
        let loop = Math.ceil(this.host.prepareOrder.length / this.pageSize);
        for (let i = 0; i < loop; i++) {
            let start = (i) * this.pageSize;
            let limit = this.pageSize;
            //
            this.printMaker(start, limit, i);
            // 发送请求给lodop
            this.host.LODOP.SET_PRINTER_INDEX(this.host.currentPrinter);
            this.host.LODOP.SET_PRINT_COPIES(this.host.printCopies);

            if (model >= 1) {
                this.host.LODOP.PRINT();
            } else {
                this.host.LODOP.PREVIEW();
            }
            // 隐藏背景图片
            {
                let req = {
                    orders: JSON.stringify(this.host.orderIds),
                    conditions: '[1,2]'
                }
                apis.saveOrderPrepareStatus(req).then((res) => {
                    if (tools.msg(res, this.host)) {

                    }
                }).catch((error) => {
                    tools.err(error, this.host);
                });
            }
        }
        if (this.host.config.close == 1) {
            setTimeout(() => {
                this.host.exit();
            }, 600);
        }

    }

    // 备货单
    printMaker(start, limit, pageIndex) {
        // 打印发货单为例，数据库存储的动态字段是 $$订单id$$  这样的信息，要想替换成动态数据
        // 需要先下载字段列表，字段列表里存储的是 [{name:'订单id',value:'id'}]这样格式的数据
        // 先用$$订单id$$去匹配订单id，获得对应的value值id,再根据类型，套入到对应的列表里获取实际的动态值
        let nzhcn = NZH.cn;
        // 先获取备货单模版信息
        // 获取订单信息
        let ORDER = this.host.prepareOrder;
        //初始化
        let printIndex = 'print' + Math.random();
        this.host.LODOP.PRINT_INIT(printIndex);

        this.host.LODOP.SET_PRINT_PAGESIZE(1, this.host.paper.width * 10, this.host.paper.height * 10, printIndex);
        // 循环
        for (var i = 0; i < this.host.dragbox.length; i++) {
            let d = this.host.dragbox[i];
            let top = d.boxObject.top;
            let left = d.boxObject.left;
            let width = d.boxObject.width;
            let height = d.boxObject.height;
            delete d.boxReverse.transform;
            delete d.boxReverse.writeingMode;
            // 拼接静态内容
            this.trans.getStaticHTML(d);
            //转换页签
            if (d.type === 'text') {
                if (d.source === 'currentPage') {
                    let allText = '第 ' + (pageIndex + 1) + ' 页';
                    this.host.LODOP.ADD_PRINT_TEXT(top, left, width, height, allText); //增加超文本项
                }
            }
            // 转换发货单数据
            if (d['type'] == 'prepare') {
                //创建表格
                let tableDiv = document.createElement('div');
                for (let s in d.boxReverse) {
                    tableDiv.style[s] = d.boxReverse[s];
                }
                let table = document.createElement('table');
                for (let s in d.tableStyle) {
                    table.style[s] = d.tableStyle[s];
                }
                //表格总框线
                table.style.border = this.tdStyle;
                tableDiv.appendChild(table);
                //处理第一行表头
                {
                    let i = 0;
                    let tr = document.createElement('tr');
                    for (let j = 0; j < d.rows[i].length; j++) {
                        let td = document.createElement('td');
                        td.setAttribute('cellspacing', 0);
                        let unit = d.rows[i][j];
                        for (let s in unit.style) {
                            td.style[s] = unit.style[s];
                        }
                        td.innerHTML = unit.valueShow;
                        //单元格框线
                        td.style.border = this.tdStyle;

                        tr.appendChild(td);
                    }
                    table.appendChild(tr);
                }
                // 统计总数问题
                let totalObj = {
                    num: 0, //商品数量总数
                };
                // 处理中间商品列表数据
                {
                    let itemTpl = tools.copy(ORDER[0]);
                    for (let i in itemTpl) {
                        itemTpl[i] = '';
                    }
                    // 获得备货单聚合数据
                    for (let i = start; i < start + limit; i++) {
                        let item = ORDER[i];
                        let key = i;
                        //判断如果对象为undefined，则要打印空行
                        if (item === undefined) {
                            item = itemTpl;
                        }
                        let tr = document.createElement('tr');
                        for (let j = 0; j < d.rows[0].length; j++) {
                            let fieldValue = this.trans.getOrderFieldValue(d.rows[0][j].valueShow);
                            let td = document.createElement('td');
                            td.setAttribute('cellspacing', 0);
                            // 因为本行是动态行，没有自己的样式，直接复制第一行的样式即可
                            let unit = d.rows[0][j];
                            for (let s in unit.style) {
                                td.style[s] = unit.style[s];
                            }
                            //转换实际显示值
                            if (j === 0) {
                                //空行不打印序号
                                if (!item.num) {
                                    td.innerHTML = '';
                                } else {
                                    td.innerHTML = key + 1;
                                }
                            } else {
                                let ffv = 0;
                                if (!!item[fieldValue]) {
                                    ffv = item[fieldValue];
                                }
                                let vv = this.trans.formatGoodsVal(fieldValue, ffv);
                                // 累积数量和价格
                                if (!!totalObj[fieldValue] && totalObj[fieldValue] !== undefined) {
                                    (totalObj[fieldValue] += parseFloat(vv));
                                }
                                //
                                if (fieldValue === 'pic_path') {
                                    let img = document.createElement('img');
                                    img.style.width = "40px";
                                    img.style.height = "40px";
                                    img.setAttribute('src', item[fieldValue]);
                                    td.appendChild(img);
                                } else {
                                    let innerHTML = this.trans.formatGoodsVal(fieldValue, item[fieldValue]);
                                    td.innerHTML = innerHTML ? innerHTML : '';
                                }
                            }
                            //单元格框线
                            td.style.border = this.tdStyle;

                            tr.appendChild(td);
                        }
                        table.appendChild(tr);
                    }
                }
                // 统计总数问题
                totalObj = {
                    num: 0, //商品数量总数
                };
                // 获得备货单聚合数据
                ORDER.forEach((item, key) => {
                    for (let j = 0; j < d.rows[0].length; j++) {
                        let fieldValue = this.trans.getOrderFieldValue(d.rows[0][j].valueShow);
                        //转换实际显示值
                        if (j === 0) {

                        } else {
                            let vv = this.trans.formatGoodsVal(fieldValue, item[fieldValue]);
                            // 累积数量和价格
                            totalObj[fieldValue] !== undefined && (totalObj[fieldValue] += parseFloat(vv));
                        }
                    }
                });
                // 处理尾部统计
                {
                    let i = 0;
                    let tr = document.createElement('tr');
                    for (let j = 0; j < d.rows[i].length; j++) {
                        let td = document.createElement('td');
                        td.setAttribute('cellspacing', 0);
                        let unit = d.rows[i][j];
                        for (let s in unit.style) {
                            td.style[s] = unit.style[s];
                        }
                        let fieldValue = this.trans.getOrderFieldValue(d.rows[0][j].valueShow);
                        let txt = '';
                        if (unit.valueShow === '序号') {
                            txt = '汇总：';
                        } else {
                            txt = totalObj[fieldValue] !== undefined ? totalObj[fieldValue] : '';
                        }
                        //单元格框线
                        td.style.border = this.tdStyle;
                        td.innerHTML = txt;
                        tr.appendChild(td);
                    }
                    table.appendChild(tr);
                }

                this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, tableDiv.outerHTML); //增加超文本项
            }
        }
    }

}

export default {
    Printer
}