import JSONBIG from 'json-bigint';
import tools from '../../../extends/tools.js';
import NZH from 'nzh';

class OrderTrans {
    constructor(host) {
            this.host = host;
        }
        // 格式化JSON数据
    parse(obj) {
        return JSONBIG.parse(obj);
    }
    getStaticHTML(d) {
        let top = d.boxObject.top;
        let left = d.boxObject.left;
        let width = d.boxObject.width;
        let height = d.boxObject.height;
        let deg = d.boxObject.deg;
        let textFontSize = d.textObject.fontSizeNum;
        let nzhcn = NZH.cn;

        // 直接打印
        if (d.type == 'text') {
            let textObject = d.textObject;
            if (deg == 90) {
                let tmp = width;
                width = height;
                height = tmp + 20;
                top -= (height - width) / 2;
                left += (height - width) / 2;
            }
            let className = 'text' + Math.random();
            let allText = d.text + '';
            // 处理大写转换
            if (textObject.textTransform == "uppercase") {
                allText = nzhcn.toMoney(allText);
                allText = allText.replace('人民币', '');
            }
            var p = '';
            this.host.LODOP.ADD_PRINT_TEXTA(className, top, left, width, height, allText); //增加超文本项
            // p += "this.host.LODOP.ADD_PRINT_TEXTA('"+className+"',"+top+","+left+","+width+","+height+",'"+d.text+"');";
            // 旋转变换
            if (deg == 90) {
                this.host.LODOP.SET_PRINT_STYLEA(className, "LineSpacing", -3);
            }
            // 处理居中居左
            if (d.boxReverse.textAlign == 'center') {
                this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 2);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Alignment',2);";
            } else if (d.boxReverse.textAlign == 'right') {
                this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 3);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Alignment',3);";
            }

            this.host.LODOP.SET_PRINT_STYLEA(className, 'FontName', textObject.fontFamily);
            // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','FontName','"+textObject.fontFamily+"');";
            this.host.LODOP.SET_PRINT_STYLEA(className, 'FontSize', textFontSize);
            // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','FontSize',"+textObject.fontSizeNum+");";

            if (textObject.fontWeigiht == 'bold') {
                this.host.LODOP.SET_PRINT_STYLEA(className, 'Bold', 1);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Bold',1);";
            }
            if (textObject.fontStyle == 'italic') {
                this.host.LODOP.SET_PRINT_STYLEA(className, 'Italic', 1);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Italic',1);";
            }
            if (textObject.textDecoration == 'underline') {
                this.host.LODOP.SET_PRINT_STYLEA(className, 'Underline', 1);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Underline',1);";
            }
            // console.log(p);
        }
        //转换线条
        if (d['type'] == 'lineBox') {
            let div = document.createElement('div');
            for (let s in d.boxReverse) {
                div.style[s] = d.boxReverse[s];
            }
            let l = document.createElement('div');
            for (let s in d.lineStyle) {
                l.style[s] = d.lineStyle[s];
            }

            div.appendChild(l);
            this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, div.outerHTML);
        }
        //
        if (d['type'] == 'barCode' && !d.isDynamic) {
            if (deg == 90) {
                let tmp = width;
                width = height;
                height = tmp;
                top -= (height - width) / 2;
                left += (height - width) / 2;
            }
            if (!d.isDynamic) {
                this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, d.format, d.staticText);
            }
            if (deg == 90) {
                this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
            }
        }
        // 转换二维码
        if (d['type'] == 'qr' && !d.isDynamic) {
            if (deg == 90) {
                let tmp = width;
                width = height;
                height = tmp;
                top -= (height - width) / 2;
                left += (height - width) / 2;
            }
            this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, 'QRCode', d.staticText);
            if (deg == 90) {
                this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
            }
        }

        // 转换图片
        if (d['type'] == 'image') {
            let div = document.createElement('div');
            for (let s in d.boxReverse) {
                div.style[s] = d.boxReverse[s];
            }
            // 旋转
            if (d.boxObject.deg == 90) {
                div.style['writeingMode'] = 'tb-rl';
            }

            let img = document.createElement('img');
            img.setAttribute('src', d.url);
            img.style.width = '100%';
            img.style.height = '100%';
            div.appendChild(img);
            this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, div.outerHTML); //增加超文本项
        }
        // 转换表格数据
        if (d['type'] == 'table') {
            //创建表格
            let tableDiv = document.createElement('div');
            for (let s in d.boxReverse) {
                tableDiv.style[s] = d.boxReverse[s];
            }

            let table = document.createElement('table');
            for (let s in d.tableStyle) {
                table.style[s] = d.tableStyle[s];
            }
            tableDiv.appendChild(table);
            //创建行和列
            for (let i = 0; i < d.rows.length; i++) {
                let tr = document.createElement('tr');
                for (let j = 0; j < d.rows[i].length; j++) {
                    let td = document.createElement('td');
                    td.setAttribute('cellspacing', 0);
                    let unit = d.rows[i][j];
                    for (let s in unit.style) {
                        td.style[s] = unit.style[s];
                    }
                    // td.style.fontFamily = '微软雅黑';
                    if (unit.isDynamic == '静态') {
                        td.innerHTML = '<pre style="display:inline;font-family:inherit;">' + unit.valueShow + '</pre>';
                    }

                    tr.appendChild(td);
                }
                table.appendChild(tr);
            }
            let html = tableDiv.outerHTML;
            this.host.LODOP.ADD_PRINT_TABLE(top, left, width, height, html); //增加超文本项
        }
    }


    //转换订单复杂数据
    transOrderdata(d, rowdata) {
        let m = d.text ? d.text + ':' : '';
        let allText = d.text;

        // 转换复杂数据,店铺信息
        if (d.source === 'shop_name') {
            allText = allText + m + rowdata.shop_info.name;
        }
        // 会员信息
        if (d.source === 'integral') {
            allText = allText + m + (!!rowdata.member_info ? rowdata.member_info.integral : '-');
        }
        if (d.source === 'grade_name') {
            allText = allText + m + (!!rowdata.member_info ? rowdata.member_info.grade_name : '-');
        }
        if (d.source === 'integral_grade_name') {
            allText = allText + m + (!!rowdata.member_info ? rowdata.member_info.integral_grade_name : '-');
        }
        if (d.source === 'name') {
            allText = allText + m + (!!rowdata.member_info ? rowdata.member_info.name : '-');
        }
        if (d.source === 'nick') {
            allText = allText + m + (!!rowdata.member_info ? rowdata.member_info.nick : '-');
        }

        return allText;
    }

    // 根据字段的中文名获取实际字段名(仅限发货单和快递单使用)
    getOrderFieldValue(label) {
        let val = '';
        let f = this.host.fieldList;
        // 备货单字段转换
        if (this.host.ptype === 2) {
            for (let index in f) {
                if (f[index] === label) {
                    val = index;
                }
            }
            return val;
        }
        // 发货单字段转换
        for (let index in f) {
            for (let i in f[index]) {
                if (f[index][i] === label) {
                    val = i;
                }
            }
        }
        return val;
    }

    getComplexDataFlag(d) {
        let flag = false;
        if (d.source === 'shop_name' ||
            d.source === 'integral' ||
            d.source === 'grade_name' ||
            d.source === 'integral_grade_name' ||
            d.source === 'name' ||
            d.source === 'nick') {
            flag = true;
        }
        return flag;
    }

    getExpressTpl(id) {
        let t = null;
        // console.log(this.host.templateList_express);
        // console.log('-0----');
        this.host.templateList_express.forEach((item, index) => {
            if (item.id === id) {
                t = item;
            }
        });
        return t;
    }

    // 打印发货单，备货单
    formatGoodsVal(field, value) {
        if (field === 'payment' || field === 'price') {
            value = tools.setPrice(value);
        }
        if (value == 0) {
            return '';
        }
        return value;
    }

    getShipmentTemplate(id) {
        let t = null;
        this.host.templateList_normal.forEach((item, index) => {
            if (item.id === id) {
                t = item;
            }
        });
        return t;
    }
}

export default {
    OrderTrans
}