<template>
  <div>
    
  </div>
</template>

<script>
import printLodop from '../../print/printExpress.vue';
export default {
    props:{
        //订单id
        orderId:{
            type:Array,
            default:[]
        }
    },
    data(){
        return {
            //通用退出打印
            printLodopExit: false,
        }
    },
    components:{
        printLodop
    }
}
</script>

<style>

</style>