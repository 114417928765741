import apis from "../../common/js/api2";
import tools from '../../../extends/tools.js';
import JSONBIG from 'json-bigint'
import { Cainiao } from '../../../extends/cainiao.class';
import Printer from '../printOrder/printNormalExpress';

export default {
    async init() {
        // 初始化菜鸟
        this.cainiao = new Cainiao();
        setTimeout(() => {
                this.printerListCainiao = this.cainiao.printerList;
            }, 1000)
            //选择打印机
        this.selectPrinter();
        //判断打印来源
        // 角色一打印操作
        if (this.printAction === 'simple') {
            this.selectPrinterFlag1 = true;
            this.tplId = this.printId;
            return;
        }
        // 角色二，打印快递单
        this.selectPrinterFlag3 = true;
        //获取模版列表
        this.ptype = 3;
        // 获取菜鸟模版列表
        await apis.getUserCainiaoTemplate().then((res) => {
            if (tools.msg(res, this)) {
                this.templateList_cainiao = res.data;
            }
        }).catch((error) => {
            tools.err(error, this);
        });

        //获取快递公司列表
        await apis.expressList().then((res) => {
            tools.msg(res, this) && (this.expressList = res.data);
        }).catch((error) => {
            tools.err(error, this);
        });

        // 非菜鸟打印-获取模版
        await apis.printList({
            type: this.ptype
        }).then((res) => {
            // 获取对应的模版列表
            this.templateList_normal = [];
            if (tools.msg(res, this)) {
                let tpl = res.data.lists;
                this.templateList_express = tpl;
                // 先获得一个不重复的快递公司集合
                let s = new Set();
                tpl.forEach((i, t) => {
                    s.add(i.logistics_companies_id);
                });
                let arr = Array.from(s);
                // 
                arr.forEach((item, key) => {
                    let ex = this.getExpressInfo(item);
                    if (ex) {
                        let obj = {
                            id: item,
                            label: ex.name,
                            code: ex.code,
                            reg_mail_no: ex.reg_mail_no,
                            value: item,
                            children: []
                        };
                        this.templateList_normal.push(obj);
                    }
                });
                // 获取第二级的快递单信息
                this.templateList_normal.forEach((item, key) => {
                    tpl.forEach((i) => {
                        if (item.id === i.logistics_companies_id) {
                            let obj = {
                                label: i.names,
                                // 快递单id
                                value: i.id,
                                // 快递单类型
                                type: item.id,
                                // 快递单代码
                                code: item.code,
                                // 验证规则
                                reg_mail_no: item.reg_mail_no
                            };
                            item.children.push(obj);
                        }
                    });
                });
                console.log(this.templateList_normal);
            }
        }).catch((error) => {
            tools.err(error, this);
        });

        //获取订单信息
        apis.getOrderByIds({
            orders: JSONBIG.stringify(this.orderIds)
        }).then((res) => {
            if (tools.msg(res, this)) {
                this.orderList = res.data;
                // 订单长度
                this.orderLength = this.orderList.length;
                // 获取默认快递单号
                this.orderList.forEach((item, index) => {

                    for (let o in item) {
                        if (item[o] === null) {
                            item[o] = '';
                        }
                    }

                    // 获取简明快递单号
                    // 每个订单需要打印的电子面单数量
                    this.$set(item, 'print_number', 0);
                    this.$set(item, 'showCainiaoDetail', false);
                    this.$set(item, 'showGoodsDetail', false);

                    // 获取商品总数量
                    let all_num = 0;
                    item.titleStr = '';
                    item.list_info.forEach((i) => {
                        all_num += i.num;
                        item.titleStr += i.title + ';';
                    });
                    this.$set(item.list_info, 'all_num', all_num);

                    if (item.waybill_info.length > 0) {
                        item.waybill_info.forEach((i, d) => {
                            if (d === 0) {
                                this.$set(item, 'waybill_code', i.waybill_code);
                            }
                        });
                    } else {
                        this.$set(item, 'waybill_code', '');
                    }
                });

            }

        }).catch((error) => {
            tools.err(error, this);
        });


        //获取对应的数据源字段，备货单和快递单，发货单不一样,
        apis.getDynamicField({
            type: this.ptype
        }).then((res) => {
            tools.msg(res) && (this.fieldList = res.data);
        }).catch((error) => {
            tools.err(error, this);
        });

        // 获取打印配置
        apis.getPrintSet().then(res => {
            if (tools.msg(res, this)) {
                this.printConfig = res.data.basic;
            }
        }).catch(err => {
            tools.err(err, this);
        });

    },
    // 快递模版
    manageExpressTpl() {
        this.$router.push({
            path: '/print'
        });
    },
    // 快递模版
    manageCainiaoTpl() {
        this.$router.push({
            path: '/cainiao/lists'
        });
    },
    // 修改菜鸟模版信息
    changeTemplate(item) {
        this.cainiaoTemplateDetail = item;
        this.currentPrinterCainiao = item.print_name;
        // 根据详情获取菜鸟账户信息
    },
    // 菜鸟信息
    showCainiao() {
        this.cainiaoAccountFlag = true;
    },
    //打印普通快递单
    async printOrder() {
        // 初始化打印对象
        this.printer = new Printer.Printer(this, this);
        this.printer.printOrder(1, true);
    },
    // 菜鸟打印
    async printCainiao(preview) {
        // 打印机必选
        if (this.currentPrinterCainiao === '') {
            this.$alert('请选择一个打印机！', {
                beforeClose: (action, instance, done) => {

                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        // 模版必选
        if (this.templateId === '') {
            this.$alert('请先选择一个模版', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }

        // 请求打印对象
        let obj = {};
        let send_express_flag = false;
        this.orderList.forEach(item => {
            if (item.is_print_express === 1) {
                send_express_flag = true;
            }
            obj[item.id] = {
                cainiao_templates_id: this.templateId,
                count: item.print_number
            };
        });

        if (send_express_flag) {
            let can_send = false;

            await this.$confirm('部分订单已经打印过快递单了，是否重复打印？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            }).then(() => {
                can_send = true;
            }).catch(() => {
                can_send = false;
            });

            if (!can_send) {
                return;
            }
        }

        let loader = tools.loading(false, this);
        // 请求打印数据，发送给打印机
        apis.canniaoPrintNewNumber({
            orders: JSON.stringify(obj)
        }).then((res) => {
            if (tools.msg(res, this)) {
                if (tools.msg(res, this)) {
                    this.preparePrint(res, preview);
                }
            }

            tools.loading(loader, this);
            this.$message({
                type: 'success',
                message: '打印成功！'
            })

        }).catch((error) => {
            tools.err(error, this);

            tools.loading(loader, this);
        });
    },
    // 打印旧的订单
    printCainiaoOld(cainiao, order) {
        // TODO 重复打印提示和限制流程

        // 请求菜鸟打印数据
        let obj = {};
        obj[order.id] = [cainiao.waybill_code];
        let req = {
            orders: JSON.stringify(obj)
        }
        console.log(this.currentPrinterCainiao);
        let loader = tools.loading(false, this);
        apis.canniaoPrintOldNumber(req).then((res) => {
            if (tools.msg(res, this)) {
                this.preparePrint(res);
            }

            tools.loading(loader, this);

        }).catch((error) => {
            tools.err(error);

            tools.loading(loader, this);

        });
        // console.log(cainiao);
        // console.log(order);
        // console.log(req);
    },
    // 准备打印工作
    preparePrint(res) {
        //发送请求给菜鸟打印机
        let index = 0;
        let timer = setInterval(() => {
            res.data[index].print_name = this.currentPrinterCainiao ? this.currentPrinterCainiao : res.data[index].print_name;
            this.cainiao.doPrint(res.data[index]);
            index++;
            if (index === res.data.length) {
                clearInterval(timer);
            }
        }, 60);
    },
    // 保存菜鸟账户信息
    saveCainiaoAccount() {
        let saveData = {
            waybill_address_id: this.selectCainiaoSite,
            send_phone: this.cainiaoSendPhone,
            send_name: this.cainiaoSender,
            default: this.cainiaoSetDefault ? 1 : 0
        };
        // 保存
        apis.saveCainiaoAccount(saveData).then((res) => {
            if (tools.msg(res, this, 1)) {}
        }).catch((error) => {
            tools.err(error, this);
        });
    },
    newTpl() {
        this.$router.push();
    },
    // 格式化JSON数据
    parse(obj) {
        return JSONBIG.parse(obj);
    },
    exit() {
        this.$emit('printLodopExit');
    },
    back() {
        this.$router.push({
            path: '/print'
        })
    },
    // 根据cp_code获取快递公司名称
    getNameByCpcode(cp_code) {
        let names = '-';
        this.expressList.forEach((item, index) => {
            if (item.code === cp_code) {
                names = item.name;
            }
        });
        return names;
    },
    // 保存普通快递单号
    saveWaybillCode(item) {
        let tpl = this.getTplById(this.currentTemplate);
        if (tpl === null) {
            return this.$message({
                type: 'error',
                message: '请先选择一个快递模版'
            });
        }
        // 获取订单id
        let obj = {};
        obj[item.id] = {
            'cp_code': tpl.code,
            'waybill_code': item.waybill_code
        };
        let d = {
            'orders': JSONBIG.stringify(obj)
        };
        //
        apis.saveWaybillCode(d).then((res) => {
            tools.msg(res, this) && this.$message({
                type: 'success',
                message: '修改运单号成功！'
            });
        }).catch((error) => {
            tools.err(error, this);
        });
    },
    getTplById(id) {
        let tpl = null;
        this.templateList_normal.forEach((item, key) => {
            item.children.forEach((i, k) => {
                if (i.value === id) {
                    tpl = i;
                }
            });
        });
        return tpl;
    },
    // 获取快递信息
    getExpressInfo(id) {
        let info = null;
        this.expressList.forEach((item, key) => {
            if (item.id === id) {
                info = item;
                return;
            }
        });
        return info;
    },
    selectPrinter() {
        //配置打印机
        let count = this.LODOP.GET_PRINTER_COUNT();
        if (count == 0) {
            this.$alert('无可用打印机', {
                beforeClose: (action, instance, done) => {

                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        let arr = [];
        for (let i = 0; i < count; i++) {
            arr.push(this.LODOP.GET_PRINTER_NAME(i));
        }
        this.printerList = arr;
    },

}