class Cainiao {
    constructor() {
        this.socket = null;
        this.printerList = [];
        this.isConnect = false;
        // 初始化就连接
        this.doConnect();
    }
    doConnect() {
        //如果是https的话，端口是13529
        //socket = new WebSocket('wss://localhost:13529');
        try {
            this.socket = new WebSocket('ws://localhost:13528');
            this.socket.onopen = (event) => {
                this.getPrinterList();
                this.isConnect = true;
            };
        } catch (err) {
            console.log(err);
        }
        // 监听菜鸟返回消息
        this.socket.onmessage = (event) => {
            var response = JSON.parse(event.data);
            // 获取打印机
            if (response.cmd == 'getPrinters') {
                this.getPrintersHandler(response); //处理打印机列表
            } else if (response.cmd == 'printerConfig') {
                this.printConfigHandler(response);
            }
        };
        // 监听Socket的关闭
        this.socket.onclose = (event) => {
            console.log('Client notified socket has closed', event);
        };
    }
    getPrinterList() {
        // {
        //     "cmd":"getPrinters",
        //     "requestID": "123458976",
        //     "version": "1.0"
        // }
        var request = this.getRequestObject("getPrinters");
        this.socket.send(JSON.stringify(request));
    }

    /***
     * 获取打印机列表
     */
    getPrintersHandler(res) {
        console.log(res);
        this.printerList = res.printers;
    }

    printConfigHandler(res) {
        console.log(res);
    }

    /***
     * 发送打印信息
     */
    doPrint(cainiaoData) {
        var request = this.getRequestObject("print");
        request.task = new Object();
        request.task.taskID = this.getUUID(8, 10);
        request.task.preview = false;
        request.task.printer = cainiaoData.print_name;

        var documents = [{
            documentID: cainiaoData.waybill_code,
            contents: [{
                    data: cainiaoData.print_data.data,
                    templateURL: cainiaoData.print_data.templateURL,
                },
                // 自定义区域
                cainiaoData.custom
            ]
        }];
        request.task.documents = documents;
        // return;
        this.socket.send(JSON.stringify(request));
    }

    dop() {
        var request = getRequestObject("print");
        request.task = new Object();
        request.task.taskID = getUUID(8, 10);
        request.task.preview = false;
        request.task.printer = printer;
        var documents = new Array();
        for (i = 0; i < waybillArray.length; i++) {
            var doc = new Object();
            doc.documentID = waybillArray[i];
            var content = new Array();
            var waybillJson = getWaybillJson(waybillArray[i]);
            var customAreaData = getCustomAreaData(waybillArray[i]);
            content.push(waybillJson, customAreaData);
            doc.content = content;
            documents.push(doc);
        }
        socket.send(JSON.stringify(request));
    }

    /***
     * 构造request对象
     */
    getRequestObject(cmd) {
        var request = new Object();
        request.requestID = this.getUUID(8, 16);
        request.version = "1.0";
        request.cmd = cmd;
        return request;
    }


    /***
     * 
     * 获取请求的UUID，指定长度和进制,如 
     * getUUID(8, 2)   //"01001010" 8 character (base=2)
     * getUUID(8, 10) // "47473046" 8 character ID (base=10)
     * getUUID(8, 16) // "098F4D35"。 8 character ID (base=16)
     *   
     */
    getUUID(len, radix) {
        var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
        var uuid = [],
            i;
        radix = radix || chars.length;
        if (len) {
            for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
        } else {
            var r;
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
            uuid[14] = '4';
            for (i = 0; i < 36; i++) {
                if (!uuid[i]) {
                    r = 0 | Math.random() * 16;
                    uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                }
            }
        }
        return uuid.join('');
    }


}

exports.Cainiao = Cainiao;